.reaction-outer {
    display: block; 
    position: absolute; 
    left: -30px; 
    top: -15x; 
}

.reaction-button.reaction-inner {
    width: 48px;
    height: 48px;

    @media (max-width: 414px){
        width: 40px;
        height: 40px;
    }
}

.reaction-inner {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.amen {
    background-image: url('/images/reactions/amen.svg');
}

.amenpsprince {
    background-image: url('/images/reactions/amenpsprince.svg');
}

.bam {
    background-image: url('/images/reactions/bam.svg');
}

.cry {
    background-image: url('/images/reactions/cry.svg');
}

.dsam {
    background-image: url('/images/reactions/dsam.svg');
}

.heart {
    background-image: url('/images/reactions/heart.svg');
}

.ohslain {
    background-image: url('/images/reactions/ohslain.svg');
}

.preachit {
    background-image: url('/images/reactions/preachit.svg');
}

.ooof {
    background-image: url('/images/reactions/ooof.svg');
}

.wow {
    background-image: url('/images/reactions/wow.svg');
}

.sogood {
    background-image: url('/images/reactions/sogood.svg');
}

.clapping {
    background-image: url('/images/reactions/clapping.svg');
}
