body {
    height: 100%;
}

// hide useless hamburger
.str-chat__header-hamburger {
    display: none;
}
// font
.str-chat *, .str-chat *::after, .str-chat *::before {
    font-family: 'Work Sans', Arial, Helvetica, sans-serif;
}

// messages - inline author style
.str-chat__message-livestream {
    padding: 0px !important;
}
.str-chat__message-livestream-author {
    display: inline-block;
}
.str-chat__message-livestream-author + div {
    display: inline-block;
}
// no inline for emoji
.str-chat__message-livestream-author + div.str-chat__message-livestream-text--is-emoji {
    display: block;
}

// channel header
.str-chat__header-livestream {
    padding: 15px !important;

    @media (max-width: 767px){
        padding: 5px 15px !important;
        min-height: 50px;
        font-size: 12px;
    }
}
.livestream.str-chat.dark .str-chat__header-livestream {
    background-color: #1f4287;
}
// channel header - live and online count
.str-chat__header-livestream-left {
    position: absolute;
    left: 70px;
}

// always show send button
@media screen and (min-width: 768px){
    .str-chat__send-button {
        display: block !important;
    }
}
.str-chat__send-button:focus {
    outline: none;
    box-shadow: 0 0 2px rgba(0, 108, 255, 1.0);
    border-radius: 6px;
}

button {
    font-weight: 600 !important;
}

// message list padding
.livestream.str-chat .str-chat__list {
    padding: 10px;
}

// remove random gaps
.str-chat__li--bottom, .str-chat__li--top {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.hideChat {
    max-height: 100vh;
}

.hideVideo {
    height: 50px !important;
    
    iframe {
        display: none;
    }
}
